<template>
    <div>
        <company-route-tabs
            v-if="isSuperAdmin"
            :company-id="entityId"
            :tab-errors="tabErrors"
        ></company-route-tabs>
        <base-form
            ref="form"
            v-model="form"
            :api="api"
            :mapper="mapper"
            :entity-id="entityId"
            :title="title"
            :disabled="disabled"
            disable-delete-button
            @created="created"
            @updated="updated"
            @cancelled="cancelled"
            @deleted="deleted"
        >
            <v-tabs-items v-model="activeTab">
                <!-- GENERAL TAB -->
                <v-tab-item :transition="false" :reverse-transition="false">
                    <form-panel>
                        <form-two-col-row>
                            <template slot="col1">
                                <!-- name -->
                                <text-input
                                    v-model="form.name"
                                    v-validate="'required|max:255|space'"
                                    v-tab-error:tab1
                                    name="name"
                                    label="labels.name"
                                    :error-messages="errors.collect('name')"
                                    :data-vv-as="$t('labels.name')"
                                ></text-input>
                            </template>
                        </form-two-col-row>
                        <form-two-col-row>
                            <template v-slot:col1>
                                <!-- administrator name -->
                                <text-input
                                    v-model="form.administratorName"
                                    v-validate="'max:255|space'"
                                    v-tab-error:tab1
                                    name="administratorName"
                                    label="labels.company_administrator_name"
                                    :error-messages="
                                        errors.collect('administratorName')
                                    "
                                    :data-vv-as="
                                        $t('labels.company_administrator_name')
                                    "
                                ></text-input>
                            </template>
                            <template v-slot:col2>
                                <!-- administrator phone -->
                                <text-input
                                    v-model="form.administratorPhone"
                                    v-validate="'phonenumber|max:255'"
                                    v-tab-error:tab1
                                    name="administratorPhone"
                                    label="labels.company_administrator_phone"
                                    :error-messages="
                                        errors.collect('administratorPhone')
                                    "
                                    :data-vv-as="
                                        $t('labels.company_administrator_phone')
                                    "
                                    @blur="
                                        form.administratorPhone = formatPhoneNumber(
                                            form.administratorPhone
                                        )
                                    "
                                ></text-input>
                            </template>
                        </form-two-col-row>
                        <form-two-col-row>
                            <template v-slot:col1>
                                <!-- accepted payment methods -->
                                <entity-select-input
                                    v-model="form.acceptedPaymentMethodList"
                                    v-validate="'required'"
                                    v-tab-error:tab1
                                    label="labels.accepted_payment_methods"
                                    :data-vv-as="
                                        $t('labels.accepted_payment_methods')
                                    "
                                    :entity="
                                        selectApiClientType.PAYMENT_METHODS
                                    "
                                    name="acceptedPaymentMethodList"
                                    autocomplete
                                    :error-messages="
                                        errors.collect(
                                            'acceptedPaymentMethodList'
                                        )
                                    "
                                    multi-select
                                    translate-items
                                ></entity-select-input>
                            </template>
                        </form-two-col-row>
                        <form-two-col-row v-if="isSuperAdmin">
                            <template v-slot:col1>
                                <!-- service fee percent -->
                                <text-input
                                    v-model="form.serviceFeePercent"
                                    v-validate="
                                        'required|min_value:0|max_value:100'
                                    "
                                    v-tab-error:tab1
                                    name="serviceFeePercent"
                                    label="labels.service_fee_percent"
                                    type="number"
                                    suffix="%"
                                    :error-messages="
                                        errors.collect('serviceFeePercent')
                                    "
                                    :data-vv-as="
                                        $t('labels.service_fee_percent')
                                    "
                                ></text-input>
                            </template>
                            <template v-slot:col2>
                                <!-- is service fee visible -->
                                <checkbox-input
                                    v-model="form.isServiceFeeVisible"
                                    v-validate="{}"
                                    v-tab-error:tab1
                                    name="isServiceFeeVisible"
                                    label="labels.is_service_fee_visible"
                                    :error-messages="
                                        errors.collect('isServiceFeeVisible')
                                    "
                                    :data-vv-as="
                                        $t('labels.is_service_fee_visible')
                                    "
                                ></checkbox-input>
                            </template>
                        </form-two-col-row>
                        <form-two-col-row v-if="isSuperAdmin">
                            <template v-slot:col1>
                                <!-- orderConfirmationEmail -->
                                <text-input
                                    v-model="form.orderConfirmationEmail"
                                    v-validate="'email'"
                                    v-tab-error:tab1
                                    name="orderConfirmationEmail"
                                    label="labels.company_order_confirmation_email"
                                    :error-messages="
                                        errors.collect('orderConfirmationEmail')
                                    "
                                    :data-vv-as="
                                        $t(
                                            'labels.company_order_confirmation_email'
                                        )
                                    "
                                ></text-input>
                            </template>
                            <template v-slot:col2>
                                <!-- deliveryCompletedEmail -->
                                <text-input
                                    v-model="form.deliveryCompletedEmail"
                                    v-validate="'email'"
                                    v-tab-error:tab1
                                    name="deliveryCompletedEmail"
                                    label="labels.company_delivery_completed_email"
                                    :error-messages="
                                        errors.collect('deliveryCompletedEmail')
                                    "
                                    :data-vv-as="
                                        $t(
                                            'labels.company_delivery_completed_email'
                                        )
                                    "
                                ></text-input>
                            </template>
                        </form-two-col-row>
                        <form-two-col-row v-if="isSuperAdmin" class="mt-0">
                            <template v-slot:col1>
                                <!-- deliveryDriverDeliveringEmail -->
                                <text-input
                                    v-model="form.deliveryDriverDeliveringEmail"
                                    v-validate="'email'"
                                    v-tab-error:tab1
                                    name="deliveryDriverDeliveringEmail"
                                    label="labels.company_driver_delivering_email"
                                    :error-messages="
                                        errors.collect(
                                            'deliveryDriverDeliveringEmail'
                                        )
                                    "
                                    :data-vv-as="
                                        $t(
                                            'labels.company_driver_delivering_email'
                                        )
                                    "
                                ></text-input>
                            </template>
                            <template v-slot:col2></template>
                        </form-two-col-row>
                        <form-two-col-row v-if="isSuperAdmin">
                            <template v-slot:col1>
                                <!-- delivery notification template -->
                                <entity-select-input
                                    v-model="form.deliveryCompletedTemplate"
                                    v-validate="''"
                                    v-tab-error:tab1
                                    label="labels.delivery_completed_template"
                                    :data-vv-as="
                                        $t('labels.delivery_completed_template')
                                    "
                                    :entity="
                                        selectApiClientType.DELIVERY_NOTIFICATION_TEMPLATE
                                    "
                                    name="deliveryCompletedTemplate"
                                    :error-messages="
                                        errors.collect(
                                            'deliveryCompletedTemplate'
                                        )
                                    "
                                    translate-items
                                ></entity-select-input>
                            </template>
                            <template v-slot:col2>
                                <checkbox-input
                                    v-model="form.ssoNewRedirect"
                                    v-validate="{}"
                                    v-tab-error:tab1
                                    name="ssoNewRedirect"
                                    label="labels.sso_new_redirect"
                                    :error-messages="
                                        errors.collect('ssoNewRedirect')
                                    "
                                    :data-vv-as="$t('labels.sso_new_redirect')"
                                ></checkbox-input>
                            </template>
                        </form-two-col-row>
                    </form-panel>

                    <form-panel
                        v-if="isSuperAdmin"
                        :title="$t('labels.delivery_price_override')"
                    >
                        <template v-slot:headerActions>
                            <v-btn
                                small
                                outlined
                                color="success"
                                @click="addDeliveryPriceOverride"
                            >
                                <v-icon left>add</v-icon>
                                {{ $t('actions.add') }}
                            </v-btn>
                        </template>
                        <div></div>
                        <v-row
                            v-for="(override,
                            index) in form.deliveryPriceOverrides"
                            :key="index"
                        >
                            <v-col cols="5" class="pb-0 pt-2">
                                <currency-input
                                    v-model="override.min_order_value"
                                    v-validate="'min_value:0|decimal:2'"
                                    v-tab-error:tab1
                                    :name="`min_order_value-${index}`"
                                    label="labels.minimum_order"
                                    :error-messages="
                                        errors.collect(
                                            `min_order_value-${index}`
                                        )
                                    "
                                    :data-vv-as="$t('labels.minimum_order')"
                                ></currency-input>
                            </v-col>
                            <v-col cols="5" class="pb-0 pt-2">
                                <currency-input
                                    v-model="override.delivery_price"
                                    v-validate="'min_value:0|decimal:2'"
                                    v-tab-error:tab1
                                    :name="`delivery_price-${index}`"
                                    label="labels.delivery_price_override"
                                    :error-messages="
                                        errors.collect(
                                            `delivery_price-${index}`
                                        )
                                    "
                                    :data-vv-as="
                                        $t('labels.delivery_price_override')
                                    "
                                ></currency-input>
                            </v-col>
                            <v-col cols="1" class="pb-0 pt-3">
                                <v-btn
                                    v-if="index > 0"
                                    icon
                                    color="error"
                                    @click="removeDeliveryPriceOverride(index)"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </form-panel>

                    <form-panel :title="$t('labels.notifications')">
                        <form-two-col-row v-if="isSuperAdmin">
                            <template v-slot:col1>
                                <!-- delivery completed notification enable -->
                                <checkbox-input
                                    v-model="
                                        form.enableDeliveryCompletedNotification
                                    "
                                    v-validate="{}"
                                    v-tab-error:tab1
                                    name="enableDeliveryCompletedNotification"
                                    label="labels.enable_delivery_completed_notification"
                                    :error-messages="
                                        errors.collect(
                                            'enableDeliveryCompletedNotification'
                                        )
                                    "
                                    :data-vv-as="
                                        $t(
                                            'labels.enable_delivery_completed_notification'
                                        )
                                    "
                                ></checkbox-input>
                                <!-- canteen reminder notification enable -->
                                <checkbox-input
                                    v-model="
                                        form.enableCanteenReminderNotification
                                    "
                                    v-validate="{}"
                                    v-tab-error:tab1
                                    name="enableCanteenReminderNotification"
                                    label="labels.enable_canteen_reminder_notification"
                                    :error-messages="
                                        errors.collect(
                                            'enableCanteenReminderNotification'
                                        )
                                    "
                                    :data-vv-as="
                                        $t(
                                            'labels.enable_canteen_reminder_notification'
                                        )
                                    "
                                ></checkbox-input>
                                <!-- enable Canteen Available Notification -->
                                <checkbox-input
                                    v-model="
                                        form.enableCanteenAvailableNotification
                                    "
                                    v-validate="{}"
                                    v-tab-error:tab1
                                    name="enableCanteenAvailableNotification"
                                    label="labels.enable_canteen_available_notification"
                                    :error-messages="
                                        errors.collect(
                                            'enableCanteenAvailableNotification'
                                        )
                                    "
                                    :data-vv-as="
                                        $t(
                                            'labels.enable_canteen_available_notification'
                                        )
                                    "
                                ></checkbox-input>
                            </template>
                            <template v-slot:col2>
                                <!-- default canteen notification time -->
                                <text-input
                                    v-model="
                                        form.defaultCanteenNotificationTime
                                    "
                                    v-validate="
                                        'required|min_value:0|max_value:300'
                                    "
                                    v-tab-error:tab1
                                    name="defaultCanteenNotificationTime"
                                    label="labels.default_canteen_notification_time"
                                    type="number"
                                    :error-messages="
                                        errors.collect(
                                            'defaultCanteenNotificationTime'
                                        )
                                    "
                                    :data-vv-as="
                                        $t(
                                            'labels.default_canteen_notification_time'
                                        )
                                    "
                                ></text-input>
                                <checkbox-input
                                    v-model="form.enableQrNotificationDelivered"
                                    name="enableQrNotificationDelivered"
                                    label="labels.enable_qr_notification_delivered"
                                ></checkbox-input>
                            </template>
                        </form-two-col-row>
                    </form-panel>

                    <!-- RESTAURANTS -->
                    <form-panel :title="$t('labels.restaurants')">
                        <form-two-col-row>
                            <template slot="col1">
                                <!-- Block all restaurants by default -->
                                <checkbox-input
                                    v-model="form.hideAllRestaurants"
                                    v-validate="{}"
                                    v-tab-error:tab1
                                    name="hideAllRestaurants"
                                    label="labels.block_all_restaurants"
                                    :error-messages="
                                        errors.collect('hideAllRestaurants')
                                    "
                                    :data-vv-as="
                                        $t('labels.block_all_restaurants')
                                    "
                                ></checkbox-input>
                            </template>
                            <template slot="col2">
                                <!-- blocked restaurants -->
                                <entity-select-input
                                    v-if="!form.hideAllRestaurants"
                                    v-model="form.hiddenRestaurants"
                                    v-validate="{}"
                                    v-tab-error:tab1
                                    label="labels.blocked_restaurants"
                                    :data-vv-as="
                                        $t('labels.blocked_restaurants')
                                    "
                                    :entity="selectApiClientType.RESTAURANT"
                                    name="hiddenRestaurants"
                                    autocomplete
                                    :error-messages="
                                        errors.collect('hiddenRestaurants')
                                    "
                                    multi-select
                                ></entity-select-input>
                                <!-- unblocked restaurants -->
                                <entity-select-input
                                    v-if="form.hideAllRestaurants"
                                    v-model="form.hideAllExceptions"
                                    v-validate="{}"
                                    v-tab-error:tab1
                                    label="labels.unblocked_restaurants"
                                    :data-vv-as="
                                        $t('labels.unblocked_restaurants')
                                    "
                                    :entity="selectApiClientType.RESTAURANT"
                                    name="hideAllExceptions"
                                    autocomplete
                                    :error-messages="
                                        errors.collect('hideAllExceptions')
                                    "
                                    multi-select
                                ></entity-select-input>
                            </template>
                        </form-two-col-row>
                    </form-panel>

                    <!-- allow only cloud canteen orders -->
                    <form-panel
                        v-if="isSuperAdmin"
                        :title="
                            $t('labels.allow_only_cloud_canteen_orders_title')
                        "
                    >
                        <form-two-col-row>
                            <template slot="col1">
                                <!-- allow Only CloudCanteen Order -->
                                <checkbox-input
                                    v-model="form.allowOnlyCloudCanteenOrder"
                                    v-validate="{}"
                                    v-tab-error:tab1
                                    name="allowOnlyCloudCanteenOrder"
                                    label="labels.allow_only_cloud_canteen_orders"
                                    :error-messages="
                                        errors.collect(
                                            'allowOnlyCloudCanteenOrder'
                                        )
                                    "
                                    :data-vv-as="
                                        $t(
                                            'labels.allow_only_cloud_canteen_orders'
                                        )
                                    "
                                ></checkbox-input>
                            </template>
                            <template slot="col2">
                                <!-- except for these employees -->
                                <entity-select-input
                                    v-model="
                                        form.allowOnlyCloudCanteenOrderExceptions
                                    "
                                    v-validate="{}"
                                    v-tab-error:tab1
                                    label="labels.allow_only_cloud_canteen_order_exceptions"
                                    :data-vv-as="
                                        $t(
                                            'labels.allow_only_cloud_canteen_order_exceptions'
                                        )
                                    "
                                    :entity="selectApiClientType.USER"
                                    :query-params="{
                                        company: form['@id'],
                                    }"
                                    :request-disabled="!form['@id']"
                                    name="allowOnlyCloudCanteenOrderExceptions"
                                    autocomplete
                                    :error-messages="
                                        errors.collect(
                                            'allowOnlyCloudCanteenOrderExceptions'
                                        )
                                    "
                                    multi-select
                                    use-id-field
                                    :disabled="!form.allowOnlyCloudCanteenOrder"
                                ></entity-select-input>
                            </template>
                        </form-two-col-row>
                    </form-panel>
                    <form-panel
                        v-if="form['@id'] && isSuperAdmin"
                        :title="$t('labels.admins')"
                    >
                        <form-one-col-row>
                            <inline-admins-table
                                :entity="form['@id']"
                                prop-key="adminOfCompany"
                                query-param-key="company"
                                link-query-key="companyAdmin"
                            ></inline-admins-table>
                        </form-one-col-row>
                    </form-panel>
                </v-tab-item>

                <!-- COMPANY DETAILS TAB -->
                <v-tab-item
                    v-if="isSuperAdmin"
                    :transition="false"
                    :reverse-transition="false"
                >
                    <form-panel>
                        <form-two-col-row>
                            <template slot="col1">
                                <!-- registered company name -->
                                <text-input
                                    v-model="form.billingInfo.regCompanyName"
                                    v-validate="'max:255|space'"
                                    v-tab-error:tab2
                                    name="billingInfo.regCompanyName"
                                    label="labels.registered_company_name"
                                    :error-messages="
                                        errors.collect(
                                            'billingInfo.regCompanyName'
                                        )
                                    "
                                    :data-vv-as="
                                        $t('labels.registered_company_name')
                                    "
                                ></text-input>
                            </template>
                            <template slot="col2">
                                <!-- registered address country -->
                                <text-input
                                    v-model="form.billingInfo.regAddressCountry"
                                    v-validate="'max:255|space'"
                                    v-tab-error:tab2
                                    name="billingInfo.regAddressCountry"
                                    label="labels.registered_address_country"
                                    :error-messages="
                                        errors.collect(
                                            'billingInfo.regAddressCountry'
                                        )
                                    "
                                    :data-vv-as="
                                        $t('labels.registered_address_country')
                                    "
                                ></text-input>
                            </template>
                        </form-two-col-row>
                        <form-two-col-row>
                            <template slot="col1">
                                <!-- registered address city -->
                                <text-input
                                    v-model="form.billingInfo.regAddressCity"
                                    v-validate="'max:255|space'"
                                    v-tab-error:tab2
                                    name="billingInfo.regAddressCity"
                                    label="labels.registered_address_city"
                                    :error-messages="
                                        errors.collect(
                                            'billingInfo.regAddressCity'
                                        )
                                    "
                                    :data-vv-as="
                                        $t('labels.registered_address_city')
                                    "
                                ></text-input>
                            </template>
                            <template slot="col2">
                                <!-- registered address postcode -->
                                <text-input
                                    v-model="
                                        form.billingInfo.regAddressPostcode
                                    "
                                    v-validate="'max:255|space'"
                                    v-tab-error:tab2
                                    name="billingInfo.regAddressPostcode"
                                    label="labels.registered_address_postcode"
                                    :error-messages="
                                        errors.collect(
                                            'billingInfo.regAddressPostcode'
                                        )
                                    "
                                    :data-vv-as="
                                        $t('labels.registered_address_postcode')
                                    "
                                ></text-input>
                            </template>
                        </form-two-col-row>
                        <form-two-col-row>
                            <template slot="col1">
                                <!-- registered address street -->
                                <text-input
                                    v-model="form.billingInfo.regAddressStreet"
                                    v-validate="'max:255|space'"
                                    v-tab-error:tab2
                                    name="billingInfo.regAddressStreet"
                                    label="labels.registered_address_street"
                                    :error-messages="
                                        errors.collect(
                                            'billingInfo.regAddressStreet'
                                        )
                                    "
                                    :data-vv-as="
                                        $t('labels.registered_address_street')
                                    "
                                ></text-input>
                            </template>
                            <template slot="col2">
                                <!-- registered address street number -->
                                <text-input
                                    v-model="
                                        form.billingInfo.regAddressStreetNumber
                                    "
                                    v-validate="'max:255|space'"
                                    v-tab-error:tab2
                                    name="billingInfo.regAddressStreetNumber"
                                    label="labels.registered_address_street_number"
                                    :error-messages="
                                        errors.collect(
                                            'billingInfo.regAddressStreetNumber'
                                        )
                                    "
                                    :data-vv-as="
                                        $t(
                                            'labels.registered_address_street_number'
                                        )
                                    "
                                ></text-input>
                            </template>
                        </form-two-col-row>
                    </form-panel>
                    <form-panel>
                        <form-two-col-row>
                            <template slot="col1">
                                <!-- VAT number -->
                                <text-input
                                    v-model="form.billingInfo.vatNumber"
                                    v-validate="'max:255|space'"
                                    v-tab-error:tab2
                                    name="billingInfo.vatNumber"
                                    label="labels.vat_number"
                                    :error-messages="
                                        errors.collect('billingInfo.vatNumber')
                                    "
                                    :data-vv-as="$t('labels.vat_number')"
                                ></text-input>
                            </template>
                            <template slot="col2">
                                <!-- registration number -->
                                <text-input
                                    v-model="
                                        form.billingInfo.registrationNumber
                                    "
                                    v-validate="'max:255|space'"
                                    v-tab-error:tab2
                                    name="billingInfo.registrationNumber"
                                    label="labels.registration_number"
                                    :error-messages="
                                        errors.collect(
                                            'billingInfo.registrationNumber'
                                        )
                                    "
                                    :data-vv-as="
                                        $t('labels.registration_number')
                                    "
                                ></text-input>
                            </template>
                        </form-two-col-row>
                    </form-panel>

                    <contacts
                        v-model="form.billingInfo.billingContacts"
                        v-tab-error:tab2
                        name="billingContacts"
                        :title="$t('labels.contact_for_billing')"
                        :error-messages="errors.all('billingContacts')"
                    >
                        <form-two-col-row>
                            <template slot="col1">
                                <!-- billing method -->
                                <text-input
                                    v-model="form.billingInfo.billingMethod"
                                    v-validate="'max:255|space'"
                                    v-tab-error:tab2
                                    name="billingInfo.billingMethod"
                                    label="labels.billing_method"
                                    :error-messages="
                                        errors.collect(
                                            'billingInfo.billingMethod'
                                        )
                                    "
                                    :data-vv-as="$t('labels.billing_method')"
                                ></text-input>
                            </template>
                        </form-two-col-row>
                    </contacts>

                    <contacts
                        v-model="form.billingInfo.orderContacts"
                        v-tab-error:tab2
                        name="orderContacts"
                        :title="$t('labels.contact_for_delivery')"
                        :error-messages="errors.all('orderContacts')"
                    ></contacts>

                    <form-panel>
                        <form-two-col-row>
                            <template slot="col1">
                                <select-input
                                    v-model="form.billingInfo.paymentTerm"
                                    v-validate="'max:255|space'"
                                    v-tab-error:tab2
                                    :items="paymentTerms"
                                    name="billingInfo.paymentTerm"
                                    label="labels.payment_term"
                                    :error-messages="
                                        errors.collect(
                                            'billingInfo.paymentTerm'
                                        )
                                    "
                                    :data-vv-as="$t('labels.payment_term')"
                                ></select-input>
                            </template>
                            <template slot="col2">
                                <text-input
                                    v-model="form.billingInfo.serviceFee"
                                    v-validate="'max:255|space'"
                                    v-tab-error:tab2
                                    name="billingInfo.serviceFee"
                                    label="labels.service_fee"
                                    :error-messages="
                                        errors.collect('billingInfo.serviceFee')
                                    "
                                    :data-vv-as="$t('labels.service_fee')"
                                ></text-input>
                            </template>
                        </form-two-col-row>
                        <form-two-col-row>
                            <template slot="col1">
                                <!-- signee -->
                                <text-input
                                    v-model="form.billingInfo.signee"
                                    v-validate="'max:255|space'"
                                    v-tab-error:tab2
                                    name="billingInfo.signee"
                                    label="labels.signee"
                                    :error-messages="
                                        errors.collect('billingInfo.signee')
                                    "
                                    :data-vv-as="$t('labels.signee')"
                                ></text-input>
                            </template>
                            <template slot="col2">
                                <!-- group_order_fee -->
                                <text-input
                                    v-model="form.billingInfo.groupOrderFee"
                                    v-validate="'decimal:2,.'"
                                    v-tab-error:tab2
                                    name="billingInfo.groupOrderFee"
                                    label="labels.group_order_fee"
                                    :error-messages="
                                        errors.collect(
                                            'billingInfo.groupOrderFee'
                                        )
                                    "
                                    :data-vv-as="$t('labels.group_order_fee')"
                                ></text-input>
                            </template>
                        </form-two-col-row>
                    </form-panel>
                </v-tab-item>

                <!-- CC Partner relations -->
                <v-tab-item
                    v-if="isSuperAdmin"
                    :transition="false"
                    :reverse-transition="false"
                >
                    <form-panel>
                        <v-row
                            v-for="(relation, index) in form.ccPartnerRelations"
                            :key="relation['@id']"
                            class="pt-2"
                        >
                            <v-col cols="11" sm="6" class="py-0">
                                <entity-select-input
                                    v-model="relation.restaurant"
                                    v-validate.immediate="'required'"
                                    v-tab-error:tab5
                                    :name="`restaurant[${relation['@id']}]`"
                                    label="labels.restaurant"
                                    :entity="selectApiClientType.RESTAURANT"
                                    :items-filter="
                                        relationRestaurantsFilter(relation)
                                    "
                                    :needs-update="partnerSelectUpdate"
                                    autocomplete
                                    :data-vv-as="$t('labels.restaurant')"
                                    :error-messages="
                                        errors.collect(
                                            `restaurant[${relation['@id']}]`
                                        )
                                    "
                                    @input="
                                        partnerSelectUpdate = !partnerSelectUpdate
                                    "
                                ></entity-select-input>
                            </v-col>
                            <v-col cols="1" order-sm="4" class="py-0">
                                <v-btn
                                    icon
                                    color="error"
                                    @click="removePRelation(relation, index)"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="5" sm="3" class="py-0">
                                <text-input
                                    v-model="relation.deliveryFee"
                                    v-validate.immediate="'required|decimal:2'"
                                    v-tab-error:tab5
                                    :name="`delivery_fee[${relation['@id']}]`"
                                    label="labels.delivery_fee"
                                    type="number"
                                    :data-vv-as="$t('labels.delivery_fee')"
                                    :error-messages="
                                        errors.collect(
                                            `delivery_fee[${relation['@id']}]`
                                        )
                                    "
                                ></text-input>
                            </v-col>
                            <v-col cols="7" sm="2" class="py-0">
                                <text-input
                                    v-model="relation.deliveryTime"
                                    v-validate.immediate="'required|integer'"
                                    v-tab-error:tab5
                                    :name="`delivery_time[${relation['@id']}]`"
                                    label="labels.delivery_time"
                                    type="number"
                                    :data-vv-as="$t('labels.delivery_time')"
                                    :error-messages="
                                        errors.collect(
                                            `delivery_time[${relation['@id']}]`
                                        )
                                    "
                                ></text-input>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <add-c-c-p-relation
                                    @added="addPRelation"
                                ></add-c-c-p-relation>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                    </form-panel>
                </v-tab-item>
            </v-tabs-items>
        </base-form>
    </div>
</template>

<script>
import routeType from '@/router/routeType'
import ApiClientType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import FormExtend from '@/components/mixins/FormExtend'
import AuthMixin from '@/components/mixins/AuthMixin'
import PhoneNumberMixin from '@/components/mixins/PhoneNumberMixin'
import CompanyRouteTabs from './RouteTabs'
import AddCCPRelation from './AddCCPRelation'
import InlineAdminsTable from '@/components/pages/users/InlineAdminsTable'
import FormOneColRow from '@/components/form/FormOneColRow'
import Contacts from '@/components/elements/Contacts'

export default {
    components: {
        CompanyRouteTabs,
        AddCCPRelation,
        InlineAdminsTable,
        FormOneColRow,
        Contacts,
    },
    mixins: [FormExtend, AuthMixin, PhoneNumberMixin],
    data() {
        return {
            api: ApiClientType.COMPANIES,
            mapper: MapperType.COMPANIES_FORM,
            form: {
                '@id': null,
                name: null,
                administratorName: null,
                administratorPhone: null,
                isServiceFeeVisible: false,
                serviceFeePercent: '0',
                acceptedPaymentMethodList: [],
                hideAllRestaurants: false,
                deliveryCompletedTemplate: null,
                hiddenRestaurants: [],
                hideAllExceptions: [],
                ccPartnerRelations: [],
                defaultCanteenNotificationTime: 30,
                billingInfo: {
                    regCompanyName: null,
                    regAddressCountry: null,
                    regAddressCity: null,
                    regAddressPostcode: null,
                    regAddressStreet: null,
                    regAddressStreetNumber: null,
                    vatNumber: null,
                    registrationNumber: null,
                    signee: null,
                    billingContacts: [{ name: '', email: '', phone: '' }],
                    orderContacts: [{ name: '', email: '', phone: '' }],
                    billingMethod: null,
                    paymentTerm: 'EOMONTH',
                    serviceFee: null,
                },
                deliveryPriceOverrides: [],
                ssoNewRedirect: false,
                enableQrNotificationDelivered: false,
            },
            editRoute: routeType.COMPANY_EDIT,
            listRoute: routeType.COMPANY_LIST,
            partnerSelectUpdate: false,
        }
    },
    computed: {
        paymentTerms() {
            return ['EOMONTH', '15', 'last_day_of_next_month'].map((el) => ({
                title: this.$t(`payment_terms.${el}`),
                value: el,
            }))
        },
    },
    methods: {
        editRedirectParams: (data) => ({ companyId: data.id }),
        removePRelation(rel, index) {
            const id = rel['@id']
            this.$delete(this.tabErrorsContainer.tab5, `restaurant[${id}]`)
            this.$delete(this.tabErrorsContainer.tab5, `delivery_fee[${id}]`)
            this.$delete(this.tabErrorsContainer.tab5, `delivery_time[${id}]`)
            this.form.ccPartnerRelations.splice(index, 1)
        },
        addPRelation(val) {
            if (
                this.form.ccPartnerRelations &&
                Array.isArray(this.form.ccPartnerRelations)
            ) {
                this.$set(this.form, 'ccPartnerRelations', [
                    ...this.form.ccPartnerRelations,
                    val,
                ])
            } else {
                this.$set(this.form, 'ccPartnerRelations', [val])
            }
        },
        relationRestaurantsFilter(item) {
            return (values) =>
                values.filter(
                    (value) =>
                        value.key === item.restaurant ||
                        !this.form.ccPartnerRelations ||
                        this.form.ccPartnerRelations.length === 0 ||
                        !this.form.ccPartnerRelations
                            .map((rel) => rel.restaurant)
                            .includes(value.key)
                )
        },
        addDeliveryPriceOverride() {
            this.form.deliveryPriceOverrides.push({
                min_order_value: null,
                delivery_price: null,
            })
        },
        removeDeliveryPriceOverride(index) {
            this.form.deliveryPriceOverrides.splice(index, 1)
        },
    },
}
</script>
